.galleryWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 15px;
}

.galleryWrapper .single {
    width: 200px;
    cursor: pointer;
}

.galleryWrapper .single img {
    max-width: 100%;
    max-height: fit-content;
}

.galleryWrapper .single img:hover {
    transform: scale(1.02);
}

.sliderWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.sliderWrapper .btnClose, .sliderWrapper .btnPrev, .sliderWrapper .btnNext {
    position: fixed;
    cursor: pointer;
    opacity:  0.6;
    color: #fff;
    z-index: 9999;
}

.btnClose:hover, .btnNext:hover, .btnPrev:hover {
    opacity: 1;
}

.fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderWrapper .btnClose {
    top: 40px;
    right: 60px;
    font-size: 40px;
}

.sliderWrapper .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
    font-size: 40px;
}

.sliderWrapper .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
    font-size: 40px;
}