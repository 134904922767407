
.cateringContainer {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border-radius: 5px;
    align-items: center;
    justify-content:center;
    font-family: "Poppins", sans-serif;
}

.cateringImg {
    overflow: hidden;
}

.cateringInfoSection {
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid gainsboro;
}

.cateringPricingInfo {
    width: 400px;
    position: relative;
}

.cateringInfoTxt {
    margin-left: 15px;
    margin-top: 5px;
}

.cateringInfoHeader {
    margin-top: 15px;
}

.cateringBtnContainer {
   position: absolute;
   bottom: 0;
   background-color: aqua;
   width: 100%;
   height: 65px;
   justify-content: center;
   align-items: center;
   background-color: whitesmoke;
}

.cateringBtn {
    margin-left: 10px;
    position: absolute;
    top: 30%;
}

.cateringBtnWrapper {
    background-color: #01bf71;
    width: 120px;
    border-radius: 5px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10px;
    position: absolute;
    top: 21%;
}

.cateringBtnWrapper:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.05);
}

.cateringBtnLink {
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
}

.cateringBtnLink:hover {
    color: black
}

.cateringContactContainer {
    max-width: 800px;
    margin-top: 12px;
}

.subInfoSection {
    margin-top: 25px;
    margin-bottom: 20px;
    max-width: 800px;
    background-color: whitesmoke;
    text-align: center;
}

.cateringSubSection {
    padding: 15px;
}

.EmailFormContainer {
    max-width: 400px;
    padding: 10px;
}

.EmailFormInput {
    max-width: 350px;
}

.EmailFormBtn {
    margin-left: 85px;
}

.cateringContactHeader {
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 992px) {
    .cateringInfoSection {
        flex-direction: column;
    }
    .subInfoSection {
        flex-direction: column;
        margin-bottom: 0;
    }
    .cateringInfoHeader {
       margin-top: 5px;
       margin-bottom: 0;
    }
    .cateringInfoTxt {
        padding: 1px;
    }
}

