body {
    margin: 0
}

.header {
    display: flex;
    align-items: baseline;
    padding: .5rem;
    gap: 1rem;
    color: black;
    font-family: "Noto Sans", sans-serif;
}

.link {
    background: none;
    border: none;
    text-decoration: none;
    color: #777;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    padding: 0;
    color: black;
    font-family: "Noto Sans", sans-serif;
}

.dropdown.active > .link,
.link:hover {
    color:#01bf71;
}

.dropdown {
    position: relative
}

.dropdown-menu {
    position: absolute;
    left: 0;
    top: calc(100% + .25rem);
    background-color: white;
    padding: .75rem;
    border-radius: .25rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
    pointer-events: none;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dropdown.active > .link + .dropdown-menu {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}